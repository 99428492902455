/* eslint-disable react/no-unstable-nested-components */
import { Button } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { fetchControllerJSON, fetchIncidents } from '../../../api/controllersApi';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { IncidentCategoryBadge } from '../../../components/Incidents/IncidentCategoryBadge';
import { Nav } from '../../../components/Nav';
import { AbsoluteTimestamp } from '../../../components/timestamps';
import { paths } from '../../../constants';
import { ResourceNotFoundError } from '../../../errors/errors';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents',
});

const columns: Column<api.ControllerIncident>[] = [
  {
    Header: 'Created at',
    accessor: (row) => row.created_at,
    Cell: (row: CellProps<any, string>) => (
      <div>
        <AbsoluteTimestamp value={row.value} format="PPp" />
      </div>
    ),
  },
  {
    Header: 'Start time',
    accessor: (row) => row.start_time,
    Cell: (row: CellProps<any, string>) => (
      <div>
        <AbsoluteTimestamp value={row.value} format="PPp" />
      </div>
    ),
  },
  {
    Header: 'End time',
    accessor: (row) => row.end_time,
    Cell: (row: CellProps<any, string>) => (
      <div>
        <AbsoluteTimestamp value={row.value} format="PPp" />
      </div>
    ),
  },

  {
    Header: 'Category',
    accessor: (row) => row.category,
    Cell: (row: CellProps<any, string>) => (
      <div>
        <IncidentCategoryBadge category={row.value} />
      </div>
    ),
  },
];

export default function ControllerIncidentsList() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerIncidentsList),
  );

  const network = useQuery(
    ['controller', controllerName, 'json'],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(network, new ResourceNotFoundError('Controller not found'));

  const incidents =
    useQuery(['controller', controllerName, 'incidents'], () => fetchIncidents(controllerName), {
      suspense: true,
    }).data ?? [];

  useDocumentTitle('Incidents', controllerName, network);

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.IncidentDetail);

  if (incidents) {
    return (
      <AutoTable
        additionalControls={
          <Button
            arrangement="leading-icon"
            icon="plus"
            variant="tertiary"
            as={Link}
            to={makeDrawerLink(paths.drawers.IncidentCreate, { controllerName })}
          >
            Create incident
          </Button>
        }
        isRowSelected={(row) => row.sid === drawerParams?.id}
        linkProps={(row) => ({
          to: Nav.makeTo({
            drawer: makeLink(paths.drawers.IncidentDetail, {
              controllerName,
              id: row.sid,
            }),
          }),
        })}
        columns={columns}
        data={incidents}
        csvFileName={`${controllerName}-devices.csv`}
      />
    );
  }
}
