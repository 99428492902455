import {
  Button,
  MinList,
  MinListControls,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  Small2,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../constants';
import { checkDefinedOrThrow } from '../../utils/expectDefinedOrThrow';
import { makeDrawerLink } from '../../utils/makeLink';
import { AbsoluteTimestamp } from '../timestamps';
import { IncidentCategoryBadge } from './IncidentCategoryBadge';

export default function IncidentDetailContent({
  controllerName,
  incident,
}: {
  incident: api.ControllerIncident;
  controllerName: string;
}) {
  const sid = checkDefinedOrThrow(incident.sid);

  return (
    <>
      <MinList>
        <MinListItemHeader icon="clock">
          <MinListTitle>Duration</MinListTitle>
          <MinListControls>
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.IncidentEditDuration, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          </MinListControls>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Start time</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <AbsoluteTimestamp value={incident.start_time || ''} format="PPp" />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>End time</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <AbsoluteTimestamp value={incident.end_time || ''} format="PPp" />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>

      <MinList>
        <MinListItemHeader icon="information">
          <MinListTitle>Details</MinListTitle>
          <MinListControls>
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.IncidentEditDetails, {
                controllerName,
                id: sid,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          </MinListControls>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Category</MinListItemLabel>
          <MinListItemValue>
            <IncidentCategoryBadge category={incident.category || ''} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Notes</MinListItemLabel>
          <MinListItemValue>
            <Small2>{incident.notes}</Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>

      <MinList>
        <MinListItemHeader>
          <MinListTitle>History</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Created at</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <AbsoluteTimestamp value={incident.created_at || ''} format="PPp" />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Updated at</MinListItemLabel>
          <MinListItemValue>
            <Small2>
              <AbsoluteTimestamp value={incident.updated_at || ''} format="PPp" />
            </Small2>
          </MinListItemValue>
        </MinListItemPair>
      </MinList>
    </>
  );
}
