import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
  fetchCompanies,
  fetchControllerJSON,
  updateControllerMetadataJSON,
} from '../../api/controllersApi';
import { ResourceNotFoundError } from '../../errors/errors';
import { createTypedField } from '../../utils/createTypedField';
import { expectDefinedOrThrow } from '../../utils/expectDefinedOrThrow';
import { FixMeLater } from '../../utils/FixMeLater';
import { isDefinedAndNotEmpty } from '../../utils/isDefined';
import { statsStyles } from './styles';

const validNetworkMetadata = z.object({
  address: z.string(),
  squareFeet: z.number(),
  companySlug: z.string(),
  patchPanelDiagramUrl: z.string().url().optional(),
  notes: z.string().optional(),
});

export interface NetworkMetadata extends z.TypeOf<typeof validNetworkMetadata> {}

const TypedField = createTypedField<NetworkMetadata>();

export interface EditNetworkDataModalProps {
  controllerName: string;
  onClose: () => void;
}

export const EditNetworkMetadataModal = ({
  controllerName,
  onClose,
}: EditNetworkDataModalProps) => {
  const classes = statsStyles();

  const { data: networkInfo } = useQuery(
    ['controller', controllerName, 'network-info'],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const result = useQuery(['companies'], fetchCompanies);
  const companies = result.data ?? [];
  const queryClient = useQueryClient();

  const updateNetworkMetadataMutation = useMutation(
    ['update_network_metadata', networkInfo.name],
    async (values: NetworkMetadata) => {
      // TRICKY: The form deals with a company slug because that's what's
      // returned from the controller info endpoint, but the API call expects a
      // company sid.
      const companySid = companies.find((c) => c.slug === values.companySlug)?.sid;
      await updateControllerMetadataJSON(networkInfo.name, {
        address: values.address,
        square_feet: values.squareFeet,
        company_sid: companySid ?? '',
        noc_metadata: {
          patch_panel_diagram_url: values.patchPanelDiagramUrl?.trim(),
          notes: values.notes?.trim(),
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        onClose();
      },
    },
  );

  const hasExistingSlug = isDefinedAndNotEmpty(networkInfo.company_slug);

  return (
    <Modal className={classes.modal} open>
      <Formik<NetworkMetadata>
        initialValues={{
          address: networkInfo.address,
          squareFeet: networkInfo.square_feet,
          companySlug: networkInfo.company_slug,
          patchPanelDiagramUrl: networkInfo.noc_metadata?.patch_panel_diagram_url ?? '',
          notes: networkInfo.noc_metadata?.notes ?? '',
        }}
        validationSchema={toFormikValidationSchema(validNetworkMetadata)}
        onSubmit={(values) => updateNetworkMetadataMutation.mutate(values)}
      >
        {(form) => (
          <form
            onSubmit={form.handleSubmit}
            className={classes.contentContainer}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <TextField
              className={classes.formField}
              label="Network"
              value={networkInfo.name}
              disabled
              inputProps={{
                readOnly: true,
                disabled: true,
              }}
            />
            <TypedField name="companySlug">
              {({ field }) => (
                <TextField
                  className={classes.formField}
                  select
                  label="Customer"
                  variant="standard"
                  helperText={hasExistingSlug ? 'Cannot reassign controller' : null}
                  InputProps={{
                    readOnly: hasExistingSlug,
                    disabled: hasExistingSlug,
                    endAdornment: result.isLoading ? (
                      <CircularProgress color="inherit" size={20} style={{ marginRight: 20 }} />
                    ) : null,
                  }}
                  {...field}
                >
                  {companies.map((company) => (
                    <MenuItem key={company.slug} value={company.slug}>
                      {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                      {company.name} ({company.slug})
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </TypedField>
            <TypedField name="address">
              {({ field }) => (
                <TextField className={classes.formField} label="Address" {...field} />
              )}
            </TypedField>
            <TypedField name="squareFeet">
              {({ field }) => (
                <TextField
                  className={classes.formField}
                  label="Square footage"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">sq ft</InputAdornment>,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{ min: 0 }}
                  {...field}
                />
              )}
            </TypedField>
            <TypedField name="patchPanelDiagramUrl">
              {({ field }) => (
                <TextField
                  className={classes.formField}
                  label="Link to patch panel diagram"
                  type="string"
                  helperText={form.touched.patchPanelDiagramUrl && form.errors.patchPanelDiagramUrl}
                  error={Boolean(
                    form.touched.patchPanelDiagramUrl && form.errors.patchPanelDiagramUrl,
                  )}
                  {...field}
                />
              )}
            </TypedField>
            <TypedField name="notes">
              {({ field }) => (
                <TextField
                  className={classes.formField}
                  multiline
                  label="Notes"
                  type="string"
                  {...field}
                />
              )}
            </TypedField>
            {updateNetworkMetadataMutation.error && (
              <p className={classes.errorMessage}>
                {(updateNetworkMetadataMutation.error as FixMeLater)?.response?.data?.title}
              </p>
            )}
            <Box className={classes.formColumnBtn} sx={{ marginTop: 20 }}>
              <Button onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </Button>
              <Button type="submit" className={classes.saveBtn}>
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
