import {
  format as formatAbsolute,
  formatDistanceToNow,
  isAfter,
  isValid,
  parseISO,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import React from 'react';

import { isDefinedAndNotEmpty } from '../utils/isDefined';

export const isTimestampKnown = (value: string | null | undefined): value is string => {
  if (isDefinedAndNotEmpty(value)) {
    const date = new Date(value);
    return isValid(date) && isAfter(date, new Date(2010, 1, 1));
  }

  return false;
};

interface DistanceToNowTimestampProps {
  value: string;
  // eslint-disable-next-line react/no-unused-prop-types
  addSuffix?: boolean;
}

export const DistanceToNowTimestamp = ({
  value,
  addSuffix = true,
}: DistanceToNowTimestampProps) => (
  <>{formatDistanceToNow(parseISO(value), { locale: enUS, addSuffix })}</>
);

export const DistanceToNowTimestampOrNull = (props: DistanceToNowTimestampProps) =>
  isTimestampKnown(props.value) ? <DistanceToNowTimestamp value={props.value} /> : null;

export const AbsoluteTimestamp = ({
  value,
  format = 'PPppp',
}: {
  value: string;
  format?: string;
}) => <>{formatAbsolute(parseISO(value), format, { locale: enUS })}</>;
